body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --background-color: #f5f6f8;
  --card-backgroud-color: #ffffff;
  --background-dark: #0f2749;
  --accent-color: #f00;
  --white: #ffffff;
  --black: #000000;
  --button-color: #536DFE;
  --upload-button-color: #4E5DFF;
  --upload-button-hover:#3c4bda;
  --carousel-indication: #3f51b5;
  --carousel-indication-bg: #F1F3F9;
  --chart-bg: #3f51b5;
  --chart-black: #3f51b5;
  --list-color: #000;
  --list-color-border: #e0e0e0;
  --list-color-bg: #f5f5f5;
  --list-color-box: #3f51b5;
  --search-box-color: #f1f3f4;
  --search-box-color-dark: #1e314f;
  --search-box-icon: #c32424;
  --dark-col: #162744;
  --dark-row: #0f2444;
  /* add more colors as needed */
}

.splash-background {
  background: linear-gradient(135deg, #001F54 0%, #003D80 50%, #0057A1 100%);
}
